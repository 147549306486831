import { zodResolver } from "@hookform/resolvers/zod";
import type { ColumnDef, Getter } from "@tanstack/react-table";
import type { AxiosError } from "axios";
import Axios from "axios";
import type { ChangeEvent } from "react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { z } from "zod";
import { useAuthContext } from "../../../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  DeleteButtonWithText,
  GoBackButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../../../../components/CheckBoxes/CheckBoxes";
import { DelayedSpinner } from "../../../../../../components/DelayedSpinner/DelayedSpinner";
import { EditableTitle } from "../../../../../../components/EditableTitle/EditableTitle";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import {
  InfoIcon,
  LoadingIcon,
  SystemDefaultIcon,
} from "../../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../../components/Table/Table";
import {
  H3,
  SmallText,
  SoftHeader2,
  SoftHeaderMedium,
  SoftHeaderMediumDarkText,
} from "../../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../../endpoints";
import { Flex } from "../../../../../../layout/FormLayout";
import { PageWrapper } from "../../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../../layout/publicPageLayout";
import type { TranslationsSchema, UUID } from "../../../../../../types/types";
import type {
  AttributeSchema,
  AttributeTemplateSchema,
  CollectionPatchArgsSchema,
  CollectionSchema,
} from "../../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../../util/Routing";
import {
  removeUnderscore,
  rowHover,
  toTitleCase,
  useFormWrapper,
  useHasMultipleLanguages,
  useStoreState,
} from "../../../../../../util/util";
import { zodRequiredString } from "../../../../../../util/zod.util";
import { AttributesNav } from "../../components/AttributesNav";
import { SystemIconWrapper } from "../../components/PIM.components.util";
import { TemplateCollectionItems } from "../../SellerAdminTemplates/TemplateCollectionItems";
import { TemplatesNav } from "../../SellerAdminTemplates/TemplatesNav";
import { CollectionTranslations } from "../CollectionTranslations";
import {
  AddAttributeToExistingCollectionForm,
  attributeToCollectionColumnCreation,
  collectionRowToCollectionRowCreation,
} from "./AddAttributeToExistingCollectionForm";

export const TableHeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

export const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CheckBoxNoMargin = styled(CheckBoxNoLabel)`
  margin-right: 0;
`;

const localstrings = (t: (s: string) => string) => ({
  tooltipAddAttributeError: t(
    "You cannot add attributes to a system default collection"
  ),
  tooltipDeleteAttributeError: t(
    "You cannot remove attributes from a system default collection"
  ),
  tooltipDeleteCollectionError: t(
    "You cannot remove a system default collection"
  ),
  tooltipEditTitleError: t("You cannot edit a system default collection"),
  tooltipAddAttributeTemplateError: t(
    "You cannot add attributes to a default template collection"
  ),
  tooltipDeleteAttributeTemplateError: t(
    "You cannot remove attributes from a default template collection"
  ),
  tooltipDeleteCollectionTemplateError: t(
    "You cannot remove a default template collection"
  ),
  tooltipDeleteTemplateInUseError: t(
    "Products are using the template, please duplicate the template for any archival changes"
  ),
  tooltipEditTitleTemplateError: t(
    "You cannot edit a default template collection"
  ),
  tooltipEditTitlePermissionError: t(
    "You don't have permission to edit this title"
  ),
  tooltipDeleteAttributeInUseError: t(
    "Cannot delete attribute, it is currently in use"
  ),
});

const removeAttributePatchBody = (
  uuid: UUID,
  collection: CollectionSchema
): CollectionPatchArgsSchema => {
  const { name, display_name, description, columns, rows } = collection;
  return {
    name,
    display_name: display_name ?? "",
    description: description ?? "",
    columns: [...columns.map(attributeToCollectionColumnCreation)],
    columns_to_delete: [uuid],
    rows: rows ? [...rows.map(collectionRowToCollectionRowCreation)] : [],
    rows_to_delete: [],
  };
};
const isLastAttribute = (collection: AttributeSchema[]) =>
  collection.length === 1;

const MandatoryHeader = React.memo(function MandatoryHeader({
  isLoading,
  isChecked,
  onHeaderChange,
}: {
  isLoading: boolean;
  isChecked: boolean;
  onHeaderChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <>
        {isLoading ? (
          <LoadingIcon width={16} height={18} />
        ) : (
          <CheckBoxNoMargin
            name="mandatory-all"
            checked={isChecked}
            onChange={onHeaderChange}
          />
        )}
      </>
      {t("Mandatory")}
      <span
        data-tip={t(
          "Checking the box will make the attribute mandatory. Mandatory attributes must be filled in before a product can be published."
        )}
        data-for="mandatory-header-tip"
      >
        <InfoIcon fill={theme.secondaryIconColor} width={14} height={14} />
      </span>
      <ReactTooltip
        id="mandatory-header-tip"
        delayHide={500}
        clickable
        effect="solid"
      />
    </HeaderWrapper>
  );
});

const RestrictedHeaderComponent = React.memo(
  function RestrictedHeaderComponent({
    isLoading,
    isChecked,
    onHeaderChange,
  }: {
    isLoading: boolean;
    isChecked: boolean;
    onHeaderChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  }) {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <HeaderWrapper>
        <>
          {isLoading ? (
            <LoadingIcon width={16} height={18} />
          ) : (
            <CheckBoxNoMargin
              name="restricted-all"
              checked={isChecked}
              onChange={onHeaderChange}
            />
          )}
        </>
        {t("Restricted")}
        <span
          data-tip={t("Checking the box will restrict the attribute.")}
          data-for="restricted-header-tip"
        >
          <InfoIcon fill={theme.secondaryIconColor} width={14} height={14} />
        </span>
        <ReactTooltip
          id="restricted-header-tip"
          delayHide={500}
          clickable
          effect="solid"
        />
      </HeaderWrapper>
    );
  }
);

const RestrictedCell = React.memo(function RestrictedCell({
  getValue,
  row,
  onUpdate,
}: {
  getValue: () => boolean;
  row: { original: { uuid: string; object_type?: string } };
  onUpdate: (uuid: string, isChecked: boolean) => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const uuid = row.original.uuid;
  const shouldHideCheckbox =
    row.original.object_type === "product_name" ||
    row.original.object_type === "product_id";

  if (shouldHideCheckbox) {
    return null;
  }

  const handleCheckBoxChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      await onUpdate(uuid, e.target.checked);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <span style={{ margin: "3px 15px 3px 0" }}>
          <LoadingIcon width={16} height={18} />
        </span>
      ) : (
        <CheckBoxNoMargin
          name={`restricted-${uuid}`}
          checked={getValue()}
          onChange={handleCheckBoxChange}
        />
      )}
    </>
  );
});

const MandatoryCell = React.memo(function MandatoryCell({
  getValue,
  row,
  onUpdate,
}: {
  getValue: () => boolean;
  row: { original: { uuid: string } };
  onUpdate: (uuid: string, isChecked: boolean) => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const uuid = row.original.uuid;

  const handleCheckBoxChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      await onUpdate(uuid, e.target.checked);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <span style={{ margin: "3px 15px 3px 0" }}>
      <LoadingIcon width={16} height={18} />
    </span>
  ) : (
    <CheckBoxNoMargin
      name={`mandatory-${uuid}`}
      checked={getValue()}
      onChange={handleCheckBoxChange}
    />
  );
});

export const SellerAdminAttributesCollectionDetails = () => {
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const hasMultipleLanguages = useHasMultipleLanguages();
  const { id: collection_id, template_id } =
    useParams<{ id: string; template_id?: string }>();

  const [isMandatoryHeaderLoading, setIsMandatoryHeaderLoading] =
    useState(false);
  const [isRestrictedHeaderLoading, setIsRestrictedHeaderLoading] =
    useState(false);

  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });

  const { adminPath } = useRoutePath();
  const [tableData, setTableData] = useState<
    {
      uuid: UUID;
      attribute_name: string;
      attribute_type: string;
      attribute_display_name: string;
      restricted: boolean;
      mandatory?: boolean;
      object_type?: string;
    }[]
  >([]);
  const [
    showAddAttributeToCollectionForm,
    setShowAddAttributeToCollectionForm,
  ] = useState(false);

  const editTitleMethodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      z.object({
        title: zodRequiredString(t),
        type: z.enum(["name"]),
      })
    ),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const editDisplayNameMethodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      z.object({
        title: zodRequiredString(t),
        type: z.enum(["display_name"]),
      })
    ),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const { hasPermission } = useAuthContext();

  const isInTemplateSection = !!template_id;
  const isInCollectionSection = isInTemplateSection === false;

  const canEditTitle =
    (isInTemplateSection && hasPermission("modify_templates")) ||
    (!isInTemplateSection && hasPermission("modify_collections"));

  const canDeleteAttribute = isInTemplateSection
    ? hasPermission("delete_templates")
    : hasPermission("delete_collections");

  const canRemoveCollection = canDeleteAttribute;

  const { notifyError, notifySuccess } = useContext(Notifications);
  const history = useHistory();
  const location = useLocation();
  const titleRef = useRef<{ setEditMode: (editMode: boolean) => void }>(null);
  const displayNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void }>(null);

  const {
    data: attributeCollectionResponse,
    error: attributesCollectionError,
    mutate: mutateCollection,
  } = useSWR<CollectionSchema & { translations: TranslationsSchema[] }>([
    endpoints.v2_tenants_id_pim_collections_id(tenant_id, collection_id),
    useMemo(() => ({ params: { include_translations: true } }), []),
  ]);

  const { data: template, mutate: mutateTemplate } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(
    template_id ? `/v2/tenants/${tenant_id}/pim/templates/${template_id}` : null
  );

  const showMandatoryHeader = template?.show_mandatory_completion_score;

  const isLoadingAttributes =
    !attributeCollectionResponse && !attributesCollectionError;

  const isDefaultTemplate = template?.template_name === "Default Template";
  useEffect(() => {
    const handleGroupData = (data: CollectionSchema) => {
      setTableData(
        data.columns.map((attr) => ({
          uuid: attr.id,
          attribute_name: attr.name,
          attribute_type: attr.input_type,
          attribute_display_name: attr?.display_name
            ? t([attr.display_name])
            : "",
          restricted: attr.is_restricted,
          mandatory: showMandatoryHeader
            ? attr.is_mandatory ?? false
            : undefined,
          object_type: attr.object_type,
        }))
      );
    };

    if (attributeCollectionResponse) {
      handleGroupData(attributeCollectionResponse);
    }
  }, [attributeCollectionResponse, setTableData, showMandatoryHeader, t]);

  const tableColumns = useMemo<
    ColumnDef<{
      uuid: UUID;
      attribute_name: string;
      attribute_type: string;
      attribute_display_name: string;
      restricted: boolean;
      mandatory?: boolean;
      object_type?: string;
    }>[]
  >(() => {
    const handleRemoveAttributeFromCollection = async (uuid: UUID) => {
      try {
        await Axios.patch<CollectionPatchArgsSchema, CollectionSchema>(
          endpoints.v2_tenants_id_pim_collections_id(tenant_id, collection_id),
          removeAttributePatchBody(uuid, attributeCollectionResponse!)
        );
        notifySuccess(t("Attribute removed successfully"));
        if (isLastAttribute(attributeCollectionResponse!.columns)) {
          notifySuccess(t("Collection removed successfully"));
          history.push(`${adminPath}/pim/attributes/collections`);
        } else {
          mutateCollection();
        }
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Failed to remove attribute from collection"),
          {
            error,
          }
        );
      }
    };

    const isAttributesInUse = () => !!attributeCollectionResponse?.rows?.length;

    return isInTemplateSection
      ? [
          {
            header: t("Attribute Name"),
            accessorKey: "attribute_name",
          },
          {
            header: () => (
              <div style={{ textAlign: "left" }}>{t("Attribute Type")}</div>
            ),
            accessorKey: "attribute_type",
            cell: ({ getValue }: { getValue: Getter<string> }) => (
              <div style={{ textAlign: "left" }}>
                {toTitleCase(removeUnderscore(getValue()))}
              </div>
            ),
          },
          {
            header: t("Attribute Display Name"),
            accessorKey: "attribute_display_name",
          },
          {
            header: () => (
              <RestrictedHeaderComponent
                isLoading={isRestrictedHeaderLoading}
                isChecked={attributeCollectionResponse?.is_restricted ?? false}
                onHeaderChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  const isChecked = e.target.checked;
                  setIsRestrictedHeaderLoading(true);
                  try {
                    await Axios.patch<
                      { attribute_ids: "all" | string[] },
                      AttributeTemplateSchema
                    >(
                      endpoints.v2_tenants_id_or_slug_pim_templates_id_collections_id_attributes_restricted(
                        tenant_id,
                        template!.template_id,
                        attributeCollectionResponse!.id
                      ),
                      isChecked
                        ? { attributes_to_restrict: "all" }
                        : { attributes_to_unrestrict: "all" }
                    );
                    setTableData((prevData) =>
                      prevData.map((row) => ({
                        ...row,
                        restricted: isChecked,
                      }))
                    );
                    await mutateTemplate();
                    await mutateCollection();
                  } catch (error) {
                    const errorMessage = (error as AxiosError)?.response?.data
                      ?.message;
                    notifyError(
                      errorMessage
                        ? errorMessage
                        : t(
                            "Could not update restricted status. Please try again later."
                          ),
                      { error }
                    );
                  } finally {
                    setIsRestrictedHeaderLoading(false);
                  }
                }}
              />
            ),
            accessorKey: "restricted",
            enableSorting: false,
            cell: (props) => (
              <RestrictedCell
                getValue={() => props.getValue() as boolean}
                row={props.row}
                onUpdate={async (uuid, isChecked) => {
                  const patchRequestBody = isChecked
                    ? { attributes_to_restrict: [uuid] }
                    : { attributes_to_unrestrict: [uuid] };
                  try {
                    await Axios.patch<
                      {
                        attributes_to_restrict?: string[];
                        attributes_to_unrestrict?: string[];
                      },
                      AttributeTemplateSchema
                    >(
                      endpoints.v2_tenants_id_or_slug_pim_templates_id_collections_id_attributes_restricted(
                        tenant_id,
                        template!.template_id,
                        attributeCollectionResponse!.id
                      ),
                      patchRequestBody
                    );
                    setTableData((prevData) =>
                      prevData.map((row) =>
                        row.uuid === uuid
                          ? { ...row, restricted: isChecked }
                          : row
                      )
                    );
                    await mutateTemplate();
                    await mutateCollection();
                  } catch (error) {
                    const errorMessage = (error as AxiosError)?.response?.data
                      ?.message;
                    notifyError(
                      errorMessage
                        ? errorMessage
                        : t(
                            "Could not update restricted status. Please try again later."
                          ),
                      { error }
                    );
                  }
                }}
              />
            ),
          },
          ...(showMandatoryHeader
            ? [
                {
                  header: () => (
                    <MandatoryHeader
                      isLoading={isMandatoryHeaderLoading}
                      isChecked={
                        attributeCollectionResponse?.is_mandatory ?? false
                      }
                      onHeaderChange={async (
                        e: ChangeEvent<HTMLInputElement>
                      ) => {
                        const isChecked = e.target.checked;
                        setIsMandatoryHeaderLoading(true);
                        try {
                          await Axios.patch<
                            { attribute_ids: "all" | string[] },
                            AttributeTemplateSchema
                          >(
                            endpoints.v2_tenants_id_or_slug_pim_templates_id_collections_id_attributes_mandate(
                              tenant_id,
                              template!.template_id,
                              attributeCollectionResponse!.id
                            ),
                            isChecked
                              ? { attributes_to_mandate: "all" }
                              : { attributes_to_unmandate: "all" }
                          );
                          setTableData((prevData) =>
                            prevData.map((row) => ({
                              ...row,
                              mandatory: isChecked,
                            }))
                          );
                          await mutateTemplate();
                          await mutateCollection();
                        } catch (error) {
                          const errorMessage = (error as AxiosError)?.response
                            ?.data?.message;
                          notifyError(
                            errorMessage
                              ? errorMessage
                              : t(
                                  "Could not update mandatory status. Please try again later."
                                ),
                            { error }
                          );
                        } finally {
                          setIsMandatoryHeaderLoading(false);
                        }
                      }}
                    />
                  ),
                  accessorKey: "mandatory",
                  enableSorting: false,
                  cell: (props: {
                    getValue: Getter<boolean>;
                    row: { original: { uuid: string } };
                  }) => (
                    <MandatoryCell
                      getValue={() => props.getValue() as boolean}
                      row={props.row}
                      onUpdate={async (uuid, isChecked) => {
                        const patchRequestBody = isChecked
                          ? { attributes_to_mandate: [uuid] }
                          : { attributes_to_unmandate: [uuid] };
                        try {
                          await Axios.patch<
                            {
                              attributes_to_mandate?: string[];
                              attributes_to_unmandate?: string[];
                            },
                            AttributeTemplateSchema
                          >(
                            endpoints.v2_tenants_id_or_slug_pim_templates_id_collections_id_attributes_mandate(
                              tenant_id,
                              template!.template_id,
                              attributeCollectionResponse!.id
                            ),
                            patchRequestBody
                          );
                          setTableData((prevData) =>
                            prevData.map((row) =>
                              row.uuid === uuid
                                ? { ...row, mandatory: isChecked }
                                : row
                            )
                          );
                          await mutateTemplate();
                          await mutateCollection();
                        } catch (error) {
                          const errorMessage = (error as AxiosError)?.response
                            ?.data?.message;
                          notifyError(
                            errorMessage
                              ? errorMessage
                              : t(
                                  "Could not update mandatory status. Please try again later."
                                ),
                            { error }
                          );
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
          {
            // The empty header and accessor are needed so it doesn't crash.
            header: "",
            accessorKey: " ",
            cell: ({
              row: { original },
            }: {
              row: { original: { uuid: string } };
            }) =>
              canDeleteAttribute ? (
                <ButtonWithConfirmDialog
                  Button={(props) => (
                    <>
                      <DeleteButton
                        {...props}
                        datafor="attribute-delete-button"
                        datatip={
                          isDefaultTemplate
                            ? localstrings(t)
                                .tooltipDeleteAttributeTemplateError
                            : attributeCollectionResponse?.is_system
                            ? localstrings(t).tooltipDeleteAttributeError
                            : isInTemplateSection && isAttributesInUse()
                            ? t(
                                "Cannot delete attribute, it is currently in use"
                              )
                            : template?.is_frozen
                            ? localstrings(t).tooltipDeleteTemplateInUseError
                            : ""
                        }
                      />
                      <ReactTooltip id="attribute-delete-button" />
                    </>
                  )}
                  testid={"remove-individiual-attribute-from-collection"}
                  disabled={
                    !!attributeCollectionResponse?.is_system ||
                    isAttributesInUse() ||
                    template?.is_frozen ||
                    isDefaultTemplate
                  }
                  handleConfirm={() => {
                    handleRemoveAttributeFromCollection(original.uuid);
                  }}
                  confirmMessage={
                    isLastAttribute(attributeCollectionResponse?.columns ?? [])
                      ? t(
                          "Are you sure you want to remove this attribute? This collection will be removed."
                        )
                      : t("Are you sure you want to remove this attribute?")
                  }
                />
              ) : null,
          },
        ]
      : [
          {
            header: t("Attribute Name"),
            accessorKey: "attribute_name",
          },
          {
            header: () => (
              <div style={{ textAlign: "left" }}>{t("Attribute Type")}</div>
            ),
            accessorKey: "attribute_type",
            cell: ({ getValue }: { getValue: Getter<string> }) => (
              <div style={{ textAlign: "left" }}>
                {toTitleCase(removeUnderscore(getValue()))}
              </div>
            ),
          },
          {
            header: t("Attribute Display Name"),
            accessorKey: "attribute_display_name",
          },
          {
            // The empty header and accessor are needed so it doesn't crash.
            header: "",
            accessorKey: " ",
            cell: ({
              row: { original },
            }: {
              row: { original: { uuid: string } };
            }) =>
              canDeleteAttribute ? (
                <ButtonWithConfirmDialog
                  Button={(props) => (
                    <>
                      <DeleteButton
                        {...props}
                        datafor="attribute-delete-button"
                        datatip={
                          isDefaultTemplate
                            ? localstrings(t)
                                .tooltipDeleteAttributeTemplateError
                            : attributeCollectionResponse?.is_system
                            ? localstrings(t).tooltipDeleteAttributeError
                            : isInTemplateSection && isAttributesInUse()
                            ? t(
                                "Cannot delete attribute, it is currently in use"
                              )
                            : template?.is_frozen
                            ? localstrings(t).tooltipDeleteTemplateInUseError
                            : ""
                        }
                      />
                      <ReactTooltip id="attribute-delete-button" />
                    </>
                  )}
                  testid={"remove-individiual-attribute-from-collection"}
                  disabled={
                    !!attributeCollectionResponse?.is_system ||
                    isAttributesInUse() ||
                    template?.is_frozen ||
                    isDefaultTemplate
                  }
                  handleConfirm={() => {
                    handleRemoveAttributeFromCollection(original.uuid);
                  }}
                  confirmMessage={
                    isLastAttribute(attributeCollectionResponse?.columns ?? [])
                      ? t(
                          "Are you sure you want to remove this attribute? This collection will be removed."
                        )
                      : t("Are you sure you want to remove this attribute?")
                  }
                />
              ) : null,
          },
        ];
  }, [
    isInTemplateSection,
    t,
    showMandatoryHeader,
    tenant_id,
    collection_id,
    attributeCollectionResponse,
    notifySuccess,
    history,
    adminPath,
    mutateCollection,
    notifyError,
    isRestrictedHeaderLoading,
    template,
    mutateTemplate,
    isMandatoryHeaderLoading,
    canDeleteAttribute,
    isDefaultTemplate,
  ]);

  if (isLoadingAttributes) {
    return <DelayedSpinner />;
  }

  if (attributesCollectionError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error loading the collection")}
      />
    );
  }

  if (attributeCollectionResponse) {
    const handleTitleEditConfirm = async ({
      title,
      type,
    }: {
      title: string;
      type: "name" | "display_name";
    }) => {
      const canSubmit = () =>
        (type === "name" &&
          title.trim() !== attributeCollectionResponse.name) ||
        (type === "display_name" &&
          title.trim() !== attributeCollectionResponse.display_name);

      if (canSubmit()) {
        const { rows } = attributeCollectionResponse;
        try {
          await Axios.patch<CollectionPatchArgsSchema, CollectionSchema>(
            endpoints.v2_tenants_id_pim_collections_id(
              tenant_id,
              collection_id
            ),
            type === "name"
              ? {
                  name: title.trim(),
                  description: attributeCollectionResponse.description ?? "",
                  rows: rows
                    ? [...rows.map(collectionRowToCollectionRowCreation)]
                    : [],
                }
              : {
                  display_name: title.trim(),
                  description: attributeCollectionResponse.description ?? "",
                  rows: rows
                    ? [...rows.map(collectionRowToCollectionRowCreation)]
                    : [],
                }
          );
          // success notification intentionally left out because this is "inline"
          if (type === "name") {
            titleRef.current?.setEditMode(false);
          } else {
            displayNameRef.current?.setEditMode(false);
          }
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage ? errorMessage : t("Error editing collection title"),
            {
              error,
            }
          );
        }
        mutateCollection();
      }
    };

    const handleRemoveCollection = async () => {
      try {
        await Axios.delete(
          endpoints.v2_tenants_id_pim_collections_id(tenant_id, collection_id)
        );
        notifySuccess(t("Collection removed successfully"));

        isInTemplateSection
          ? history.push(
              `${adminPath}/pim/templates/${template_id}/collections`
            )
          : history.push(`${adminPath}/pim/attributes/collections`);
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("There was an error deleting the collection"),
          {
            error,
          }
        );
      }
    };

    const handleAttributesReorder = async (tableData: Array<any>) => {
      const reorderList = tableData.map((row) => row.uuid);

      try {
        await Axios.post(
          `/v2/tenants/${tenant_id}/pim/collections/${collection_id}/priority?type=collection_column`,
          {
            items: reorderList,
          }
        );
        mutateCollection();
        notifySuccess(t("Your changes have been saved successfully"));
      } catch (error) {
        notifyError(t("Something went wrong, please try again"), { error });
        attributeCollectionResponse &&
          setTableData(
            attributeCollectionResponse.columns.map((attr) => ({
              uuid: attr.id,
              attribute_name: attr.name,
              attribute_type: attr.input_type,
              attribute_display_name: attr?.display_name
                ? t([attr.display_name])
                : "",
              restricted: attr.is_restricted,
              mandatory: attr.is_mandatory ?? false,
              object_type: attr.object_type,
            }))
          );
      }
    };

    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));

    return (
      <PageWrapper>
        {isInTemplateSection ? (
          <TemplatesNav
            pageTitle={template?.template_name ?? ""}
            tabIndex={1}
            templateID={template_id!}
          />
        ) : (
          <AttributesNav tabName="Collections" params={params} />
        )}
        <div style={{ width: "fit-content" }}>
          <Link
            to={
              location.search.includes("from=dashboard")
                ? `${adminPath}/dashboard`
                : isInTemplateSection
                ? `${adminPath}/pim/templates/${template_id}/collections?${params}`
                : `${adminPath}/pim/attributes/collections?${params}`
            }
          >
            <GoBackButton text="Collections"></GoBackButton>
          </Link>
        </div>
        <TableHeaderSection>
          <HeadingSection>
            {isInTemplateSection ? (
              <>
                <H3 style={{ margin: 0 }}>
                  {attributeCollectionResponse.name}
                </H3>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <SoftHeaderMedium>{t("Display name:")}</SoftHeaderMedium>
                  <SoftHeaderMediumDarkText>
                    {t([attributeCollectionResponse.display_name])}
                  </SoftHeaderMediumDarkText>
                </div>
              </>
            ) : (
              <>
                <form
                  id="edit-name-form"
                  noValidate
                  onSubmit={editTitleMethodsOfUseForm.handleSubmit(
                    handleTitleEditConfirm
                  )}
                >
                  <EditableTitle
                    title={attributeCollectionResponse.name}
                    formId="edit-name-form"
                    name="title"
                    methodsOfUseForm={editTitleMethodsOfUseForm}
                    disabled={
                      attributeCollectionResponse.is_system ||
                      isDefaultTemplate ||
                      !hasPermission("modify_collections")
                    }
                    datatip={
                      isDefaultTemplate
                        ? localstrings(t).tooltipEditTitleTemplateError
                        : attributeCollectionResponse.is_system
                        ? localstrings(t).tooltipEditTitleError
                        : !canEditTitle
                        ? localstrings(t).tooltipEditTitlePermissionError
                        : ""
                    }
                    ref={titleRef}
                  />
                  <input
                    name="type"
                    ref={editTitleMethodsOfUseForm.register}
                    value="name"
                    type="hidden"
                  />
                </form>
                <form
                  id="edit-displayname-form"
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                  noValidate
                  onSubmit={editDisplayNameMethodsOfUseForm.handleSubmit(
                    handleTitleEditConfirm
                  )}
                >
                  <SoftHeaderMedium>{t("Display name: ")}</SoftHeaderMedium>
                  <EditableTitle
                    title={attributeCollectionResponse.display_name ?? "--"}
                    formId="edit-displayname-form"
                    name="title"
                    methodsOfUseForm={editDisplayNameMethodsOfUseForm}
                    disabled={!hasPermission("modify_collections")}
                    datatip={
                      !hasPermission("modify_collections")
                        ? localstrings(t).tooltipEditTitlePermissionError
                        : ""
                    }
                    ref={displayNameRef}
                    fontSize="regular"
                    fontWeight="regular"
                  />
                  <input
                    name="type"
                    ref={editDisplayNameMethodsOfUseForm.register}
                    value="display_name"
                    type="hidden"
                  />
                </form>
              </>
            )}
            <SmallText style={{ marginBottom: "15px" }}>
              {t("This collection consists of")}
            </SmallText>
          </HeadingSection>
          <div style={{ alignSelf: "flex-end" }}>
            {attributeCollectionResponse.is_system && (
              <Flex
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  margin: "0 0 15px",
                }}
              >
                <SystemIconWrapper
                  data-for={`system${collection_id}`}
                  data-tip={t("System Default")}
                >
                  <SystemDefaultIcon width={22} height={22} />
                  <ReactTooltip id={`system${collection_id}`} />
                </SystemIconWrapper>
                <SoftHeader2>{t("System Default")}</SoftHeader2>
              </Flex>
            )}

            {canRemoveCollection && !attributeCollectionResponse.is_system && (
              <ButtonWithConfirmDialog
                Button={(props) => (
                  <>
                    <DeleteButtonWithText
                      {...props}
                      datafor="remove-collection-button"
                      datatip={
                        isDefaultTemplate
                          ? localstrings(t).tooltipDeleteCollectionTemplateError
                          : attributeCollectionResponse.is_system
                          ? localstrings(t).tooltipDeleteCollectionError
                          : template?.is_frozen
                          ? localstrings(t).tooltipDeleteTemplateInUseError
                          : ""
                      }
                    />
                    <ReactTooltip id="remove-collection-button" />
                  </>
                )}
                buttonText={t("Remove Collection")}
                disabled={
                  attributeCollectionResponse.is_system || template?.is_frozen
                }
                testid={"remove-collection"}
                handleConfirm={handleRemoveCollection}
                confirmMessage={t(
                  "Are you sure you want to remove this collection?"
                )}
              />
            )}
          </div>
        </TableHeaderSection>
        <ContentWrapper>
          <div>
            <div style={{ marginBottom: "24px", position: "relative" }}>
              <Table
                isLoading={isLoadingAttributes}
                error={attributesCollectionError}
                columns={tableColumns}
                data={tableData}
                rowHover={rowHover}
                showReorderControls={isInTemplateSection}
                handleTableReorder={handleAttributesReorder}
                reorderConfirmationMessage={
                  template?.number_of_products &&
                  template?.number_of_products > 0
                    ? t(
                        `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                        {
                          numberOfProducts: template.number_of_products,
                          product:
                            template.number_of_products === 1
                              ? "product"
                              : "products",
                        }
                      )
                    : undefined
                }
              />
            </div>
          </div>
          {isInCollectionSection && hasPermission("modify_collections") && (
            <>
              <PrimaryButtonWithPlusIcon
                disabled={
                  attributeCollectionResponse.is_system || isDefaultTemplate
                }
                onClick={() => setShowAddAttributeToCollectionForm(true)}
                datafor="add-attribute-button"
                datatip={
                  isDefaultTemplate
                    ? localstrings(t).tooltipAddAttributeTemplateError
                    : attributeCollectionResponse.is_system
                    ? localstrings(t).tooltipAddAttributeError
                    : ""
                }
              >
                {t("Add attribute")}
              </PrimaryButtonWithPlusIcon>
              <ReactTooltip id="add-attribute-button" />
              {hasMultipleLanguages && !isInTemplateSection ? (
                <div style={{ marginTop: "32px" }}>
                  <CollectionTranslations
                    collection={attributeCollectionResponse}
                    mutate={mutateCollection}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {isInTemplateSection && (
            <>
              <H3>{t("Items")}</H3>
              <TemplateCollectionItems
                template={template}
                error={attributesCollectionError}
                isLoading={isLoadingAttributes}
                collection={attributeCollectionResponse}
                onSuccess={() => mutateCollection()}
              />
            </>
          )}
          <SlideOut
            show={showAddAttributeToCollectionForm}
            closeFlyout={() => setShowAddAttributeToCollectionForm(false)}
          >
            <AddAttributeToExistingCollectionForm
              collection={attributeCollectionResponse}
              isTemplateInUse={template?.is_frozen}
              onSuccess={() => {
                mutateCollection();
                setShowAddAttributeToCollectionForm(false);
              }}
            />
          </SlideOut>
        </ContentWrapper>
      </PageWrapper>
    );
  } else return null;
};
