import type {
  ColumnDef,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table as TableType,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import { checkTextOverflow } from "../../util/util";
import type { MenuItemProps } from "../ContextMenu/ContextMenu";
import { ContextMenuTrigger } from "../ContextMenu/ContextMenu";
import {
  CaretDownIcon,
  CaretUpIcon,
  LoadingIcon,
  ReorderIcon,
} from "../Icons/Icons";
import { TableCheckBox } from "../TableCheckBox/TableCheckBox";
import { H3 } from "../Typography/Typography";
import { TableReorderControls } from "./TableReorderControls";
import type { ITableProps, TableRefProps } from "./utils";

type ColumnContextMenuConfig = {
  items: MenuItemProps[];
  onSelect: (value: string) => void;
};

// This is a workaround to allow us to add a contextMenu property to the column object,
//  feels more natural there than a prop
declare module "@tanstack/react-table" {
  // I'm disabling this because I can't override the interface without having the type parameters,
  // even though I don't actually need them in my implementation.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue = unknown> {
    contextMenu?: ColumnContextMenuConfig;
  }
}

export const TableWrapper = styled.div<{
  lastChildleftAlign?: boolean;
  showLeftBorder?: boolean;
  enableRowReorder?: boolean;
  enableColumnResize?: boolean;
  firstRowWidthPercentage?: number;
}>`
  padding: 0;
  overflow-x: auto;
  position: relative;
  min-height: 95px;
  table {
    border-spacing: 0;
    border: 0;
    width: 100%;
    min-width: 100%;
    border: 1px solid ${({ theme }) => theme.secondaryBorder};
    border-collapse: collapse;
    th,
    td {
      margin: 0;
      padding: 16px 10px;
      border: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: ${({ enableColumnResize }) =>
        enableColumnResize ? "none" : "200px"};
      position: relative;
      :last-child {
        border-right: 0;
        text-align: ${({ lastChildleftAlign }) =>
          lastChildleftAlign ? "left" : "right !important"};
      }
      .__react_component_tooltip {
        display: ${({ enableRowReorder }) =>
          enableRowReorder ? "none !important" : "inline-block"};
      }
      .resizer {
        position: absolute;
        top: 0;
        height: 100%;
        width: 4px;
        background: rgba(0, 0, 0, 0.3);
        z-index: 2;
        cursor: col-resize;
        user-select: none;
        touch-action: none;
        opacity: 0;
      }

      .resizer.ltr {
        right: 0;
      }

      .resizer.rtl {
        left: 0;
      }

      .resizer.isResizing {
        background: blue;
        opacity: 1;
      }
    }

    th {
      color: ${({ theme }) => theme.primaryTextColor};
      font-size: ${({ theme }) => theme.fontSizes.xs};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      margin-right: 4px;
      border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
      background: ${({ theme }) => theme.secondaryBG};
      span:after {
        content: "  ";
      }
      &:hover {
        .resizer {
          opacity: 1;
        }
      }
    }
    thead th:first-child {
      border-left: ${({ showLeftBorder, theme }) =>
        showLeftBorder ? `4px solid ${theme.secondaryBorder}` : "none"};
    }
    tbody td {
      background: ${({ theme }) => theme.primaryBG};
      border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
      font-size: ${({ theme }) => theme.fontSizes.small};
      color: ${({ theme }) => theme.primaryTextColor};
      .SingleDatePickerInput_calendarIcon {
        padding: 6px 3px !important;
        top: 2px !important;
        svg {
          width: 19px !important;
          height: 19px !important;
        }
      }
      .ql-editor {
        padding: 8px;
      }
      .ql-toolbar {
        padding: 3px 0px 2px 4px !important;
        margin: 0px 0px !important;
        min-width: 175px;
      }
      .ql-formats {
        margin-right: 0 !important;
        button {
          padding: 4px !important;
          width: 24px !important;
          height: 24px !important;
          margin-right: 5px !important;
          margin-bottom: 4px !important;
          svg {
            height: 12px !important;
            width: 12px !important;
          }
        }
      }
      div.textField {
        height: auto;
        padding: 0px;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        min-width: 100px;
        input {
          padding: 7px 4px;
          font-size: ${({ theme }) => theme.fontSizes.small} !important;
          height: auto;
          line-height: 18px;
          &::placeholder {
            font-size: ${({ theme }) => theme.fontSizes.xs} !important;
          }
        }
      }

      div.select__control {
        height: auto;
        min-height: 25px;
        flex-wrap: nowrap;
        min-width: 100px;
        .select__value-container {
          margin-top: 0;
          padding: 0px 3px;
          position: relative;
          div[class*="SelectBoxShared__InterDiv"] {
            display: flex;
            margin-top: 0px !important;
            align-content: center;
            align-items: center;
            height: 32px;
          }
          .select__placeholder {
            top: 15px;
            font-size: ${({ theme }) => theme.fontSizes.xs} !important;
            position: absolute;
          }
        }
        .select__indicator {
          padding: 2px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      &:has(> a) {
        padding: 0;
        a {
          color: ${({ theme }) => theme.primaryTextColor};
          padding: 16px 10px;
          text-decoration: none;
          display: inline-block;
          width: 100%;
        }
      }
    }

    tbody tr {
      td:first-child {
        border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
        width: ${({ firstRowWidthPercentage }) =>
          firstRowWidthPercentage ? `${firstRowWidthPercentage}%` : "unset"};
      }
      &.unread td:first-child {
        border-left: 4px solid ${({ theme }) => theme.brandColor};
      }

      &:hover td {
        background: ${({ theme }) => theme.primaryButtonBG};
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
`;

const TableLoaderWrapper = styled.div<{ top: number }>`
  text-align: center;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  right: 0;
  top: ${({ top }) => (top ? `${top}px` : "53px")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loadingBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    animation: animateBg 1.5s linear infinite;
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.secondaryBG},
      ${({ theme }) => theme.brandColor},
      ${({ theme }) => theme.secondaryBG},
      ${({ theme }) => theme.brandColor}
    );
    background-size: 300% 100%;
  }

  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const TableLoaderContent = styled.div`
  background: ${({ theme }) => theme.navBG};
  display: ruby-text;
  padding: 6px 11px 7px;
  border-radius: 20px;
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: 13px;
  line-height: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 104px;
  border: 1px solid ${({ theme }) => theme.brandColor};
  top: 8px;
  position: absolute;
  svg {
    margin-right: 5px;
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const Loading = styled.div`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  background: ${({ theme }) => theme.primaryBG};
`;

export const NoData = styled.div<{ error?: boolean }>`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.secondaryTextColor};
  background: ${({ theme }) => theme.primaryBG};
`;

const StyledH3 = styled(H3)`
  margin: 0 0 10px;
`;

export type SortBy = {
  desc: boolean;
  id: string;
};

type DragItem = {
  type: string;
  index: number;
  data: any;
};

/**
 * @param handleSort - is passed into Table to enable sorting, and you must explicitly use
 *  @param enableDefaultSort - enable default table sort.
 * @param enableSorting -  for columns where sorting is not supported.
 * @param pageIndex -  is used with the
 * @param  handleSelectRows -  to ensure selected rows are persisted across pages
 */
export const Table = React.forwardRef<TableRefProps, ITableProps<any>>(
  <TableData extends object>(
    {
      columns,
      data,
      isLoading,
      title,
      handleSort,
      enableDefaultSort,
      rowHover,
      error,
      rowClick,
      lastChildleftAlign,
      Placeholder,
      handleSelectRows,
      enableReorder = false,
      showReorderControls = false,
      enableColumnResize = false,
      hiddenColumns,
      handleTableReorder,
      handleRowReorder,
      reorderConfirmationMessage,
      showLeftBorder = true,
      firstRowWidthPercentage,
      defaultSelectedRows,
      name,
      handleMoveRow,
      handleDrop,
      rowSelectionType = "checkbox",
    }: ITableProps<TableData>,
    ref: React.ForwardedRef<TableRefProps>
  ) => {
    const [records, setRecords] = useState(data);
    const [table_body, set_table_body] =
      useState<HTMLTableSectionElement | undefined>();
    const [enableRowReorder, setEnableRowReorder] =
      useState<boolean>(enableReorder);
    const [row_selection, set_row_selection] = useState<RowSelectionState>({});
    const [hasSelectedRowsChanged, setHasSelectedRowsChanged] = useState(false);
    const [is_default_rows_set, set_is_default_rows_set] = useState(false);
    const [is_all_items_selected, set_is_all_items_selected] = useState(false);
    const [dragRef] = useState(React.useRef<HTMLDivElement>(null));
    const [sortTableBy, setSortTableBy] = useState<SortingState>();
    const dropRef = React.useRef<HTMLDivElement>(null);
    const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>(
      []
    );
    useEffect(() => {
      if (columns) {
        setTableColumns([
          ...(handleSelectRows
            ? [
                {
                  id: "select",
                  header: ({ table }: { table: TableType<TableData> }) =>
                    rowSelectionType === "checkbox" ? (
                      <TableCheckBox
                        {...{
                          checked: table.getIsAllPageRowsSelected(),
                          indeterminate: table.getIsSomeRowsSelected(),
                          onChange: (e: unknown) => {
                            setHasSelectedRowsChanged(true);
                            table.getToggleAllPageRowsSelectedHandler()(e);
                          },
                          disabled: is_all_items_selected,
                          input_type: "checkbox",
                        }}
                      />
                    ) : (
                      <></>
                    ),
                  cell: ({ row }: { row: Row<TableData> }) => (
                    <div className="px-1">
                      <TableCheckBox
                        {...{
                          checked: row.getIsSelected(),
                          disabled: !row.getCanSelect(),
                          indeterminate: false,
                          ...(rowSelectionType === "radio"
                            ? {
                                handleChange: (
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setHasSelectedRowsChanged(true);
                                  row.getToggleSelectedHandler()(e);
                                },
                              }
                            : {}),
                          onChange: (e: unknown) => {
                            setHasSelectedRowsChanged(true);
                            row.getToggleSelectedHandler()(e);
                          },
                          input_type: rowSelectionType,
                        }}
                      />
                    </div>
                  ),
                  disableResize: true,
                },
              ]
            : []),
          ...columns,
        ]);
      }
    }, [columns, handleSelectRows, is_all_items_selected, rowSelectionType]);

    const [sorting, setSorting] = useState<SortingState>([]);

    const tableInstance = useReactTable({
      columns: tableColumns,
      data: records,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      enableSorting: !!handleSort || enableDefaultSort,
      enableSortingRemoval: false,
      manualSorting: !!handleSort,
      columnResizeMode: "onChange",
      columnResizeDirection: "ltr",
      enableRowSelection: !!handleSelectRows && !is_all_items_selected,
      enableMultiRowSelection: rowSelectionType === "checkbox",
      getRowId: (row: TableData) =>
        (row as { original: { id: string } })?.original?.id ?? (row as any).id,
      onSortingChange: setSorting,
      onRowSelectionChange: set_row_selection,
      initialState: {
        columnVisibility: hiddenColumns
          ? hiddenColumns.reduce((prev, cur) => ({ ...prev, [cur]: false }), {})
          : {},
      },
      state: {
        sorting,
        rowSelection: row_selection,
      },
    });

    const { t } = useTranslation();
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

    const cancelReorder = () => {
      setRecords(data);
      setEnableRowReorder(false);
    };

    const saveReorder = () => {
      if (handleTableReorder) {
        handleTableReorder(records);
      }
      setEnableRowReorder(false);
    };

    const getRowElement = (rowIdx: string) => {
      return Array.from(table_body?.children ?? []).find(
        (row: Element) =>
          (row as HTMLTableRowElement)?.dataset?.testid === rowIdx
      ) as HTMLTableRowElement;
    };

    const moveRow = <ItemType extends TableData>(
      old_index: number,
      new_index: number,
      item: ItemType
    ) => {
      const list = [...records];
      if (records.indexOf(item as TableData) > -1) {
        list.splice(new_index, 0, list.splice(old_index, 1)[0]); // move item to new index
        setRecords(list);
      } else {
        list.splice(new_index, 0, item); // insert item to new index
      }
      if (handleMoveRow) {
        handleMoveRow({
          item,
          source: name ?? "table",
          data: list,
        });
      }
    };

    const [, , preview] = useDrag({
      type: "row",
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const [, drop] = useDrop({
      accept: "row",
      drop(_item, _monitor) {
        if (handleDrop) {
          handleDrop();
        }
      },
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = (item as DragItem).index;
        const hoverIndex = 0;

        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset?.y
          ? clientOffset?.y - hoverBoundingRect.top
          : 0;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 20) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 20) {
          return;
        }
        // Time to actually perform the action
        let timeout = 0;
        timeout = setTimeout(() => {
          moveRow(dragIndex, hoverIndex, (item as DragItem).data);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          (item as DragItem).index = hoverIndex;
          clearTimeout(timeout);
        });
      },
    });

    const onResetSelectedRows = () => {
      set_is_all_items_selected(false);
      tableInstance.resetRowSelection();
      tableInstance.toggleAllRowsSelected(false);
      if (handleSelectRows) {
        handleSelectRows([]);
      }
    };

    const onSelectAllItems = () => {
      set_is_all_items_selected(true);
      set_row_selection(
        records.reduce((acc, row) => ({ ...acc, [(row as any).id]: true }), {})
      );
      tableInstance.toggleAllRowsSelected(true);
      if (handleSelectRows) {
        handleSelectRows("all");
      }
    };

    // TODO need to handle the distinction between an empty array because a search
    // result returned nothing and an empty array because there is nothing at all.
    const displayFallback = () => {
      if (!isLoading && !error && data.length === 0) {
        return Placeholder ? (
          <div style={{ padding: "0px" }} ref={dropRef}>
            {Placeholder}
          </div>
        ) : (
          <NoData>{t("No Results found.")}</NoData>
        );
      }
    };

    React.useImperativeHandle(ref, () => {
      return {
        resetSelectedRows() {
          onResetSelectedRows();
        },
        selectAllItems() {
          onSelectAllItems();
        },
        resetIsDefaultSelected() {
          set_is_default_rows_set(false);
        },
      };
    });

    useEffect(() => {
      if (enableRowReorder) {
        preview(drop(dropRef));
      }
    }, [enableRowReorder, preview, drop, dropRef, dragRef]);

    useEffect(() => {
      if (handleRowReorder && records) {
        handleRowReorder(records);
      }
    }, [records, handleRowReorder]);

    useEffect(() => {
      if (records && sortTableBy) {
        tableInstance.setSorting(sortTableBy);
      }
    }, [records, sortTableBy, tableInstance]);

    useEffect(() => {
      if (handleSort) {
        handleSort(sorting);
      }
      setSortTableBy(sorting);
    }, [handleSort, sorting]);

    useEffect(() => {
      if (handleSelectRows && hasSelectedRowsChanged) {
        handleSelectRows(
          is_all_items_selected ? "all" : Object.keys(row_selection)
        );
      }
      return () => {
        setHasSelectedRowsChanged(false);
      };
    }, [
      handleSelectRows,
      hasSelectedRowsChanged,
      is_all_items_selected,
      row_selection,
    ]);

    useEffect(() => {
      if (handleRowReorder) {
        setEnableRowReorder(enableReorder);
      }
    }, [enableReorder, handleRowReorder]);

    useEffect(() => {
      if (data) {
        setRecords(data);
        if (is_all_items_selected) {
          set_row_selection(
            data.reduce((acc, row) => ({ ...acc, [(row as any).id]: true }), {})
          );
        }
      }
    }, [data, is_all_items_selected]);

    useEffect(() => {
      if (defaultSelectedRows && !is_default_rows_set && records.length) {
        set_is_all_items_selected((prev) =>
          defaultSelectedRows === "all" ? true : prev
        );
        set_row_selection(
          defaultSelectedRows === "all"
            ? records.reduce(
                (acc, row) => ({ ...acc, [(row as any).id]: true }),
                {}
              )
            : defaultSelectedRows.reduce(
                (acc, row) => ({ ...acc, [row]: true }),
                {}
              )
        );
        set_is_default_rows_set(true);
      }
    }, [defaultSelectedRows, is_default_rows_set, records]);

    useEffect(() => {
      if (tableBodyRef.current) {
        set_table_body(tableBodyRef.current);
      }
    }, []);

    return (
      <>
        {" "}
        <FlexWrapper>
          <div>{title && <StyledH3>{title}</StyledH3>}</div>
          <div>
            {showReorderControls && records.length > 1 && (
              <TableReorderControls
                enableRowReorder={enableRowReorder}
                handleEnableRowReorder={() => setEnableRowReorder(true)}
                handleSaveReorder={saveReorder}
                handleCancelReorder={cancelReorder}
                reorderConfirmationMessage={reorderConfirmationMessage}
              />
            )}
          </div>
        </FlexWrapper>
        <TableWrapper
          id="table_wrapper_id"
          lastChildleftAlign={lastChildleftAlign}
          showLeftBorder={showLeftBorder}
          enableRowReorder={enableRowReorder}
          enableColumnResize={enableColumnResize}
          firstRowWidthPercentage={firstRowWidthPercentage}
        >
          {isLoading && (
            <TableLoaderWrapper
              top={tableHeaderRef.current?.clientHeight || 53}
            >
              <div className="loadingBar"></div>
              <TableLoaderContent>
                <LoadingIcon width={15} height={15} />
                {t("Loading")}...
              </TableLoaderContent>
            </TableLoaderWrapper>
          )}

          <table
            {...{
              style: {
                width: enableColumnResize
                  ? tableInstance.getCenterTotalSize()
                  : "100%",
              },
            }}
          >
            <thead ref={tableHeaderRef}>
              {tableInstance
                .getHeaderGroups()
                .map((headerGroup, headerGroupIndex) => (
                  <tr key={headerGroupIndex}>
                    {enableRowReorder && (
                      <th
                        key={0}
                        style={{ cursor: "pointer", width: "40px" }}
                      ></th>
                    )}
                    {headerGroup.headers.map(
                      (header: any, columnIndex: number) => (
                        <th
                          key={enableRowReorder ? columnIndex + 1 : columnIndex}
                          style={{
                            textAlign:
                              header.align === "right" ? "right" : "left",
                            width:
                              header.column.id === "select"
                                ? "20px"
                                : header.column.columnDef.width ??
                                  header.getSize(),
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={
                                (handleSort || enableDefaultSort) &&
                                header.column.getCanSort()
                                  ? { cursor: "pointer" }
                                  : undefined
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <span>
                                {{
                                  asc: <CaretUpIcon height={12} width={12} />,
                                  desc: (
                                    <CaretDownIcon height={12} width={12} />
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </span>
                            </span>
                            {header.column.columnDef.meta?.contextMenu && (
                              <ContextMenuTrigger
                                items={
                                  header.column.columnDef.meta.contextMenu.items
                                }
                                onSelect={
                                  header.column.columnDef.meta.contextMenu
                                    .onSelect
                                }
                              />
                            )}
                          </div>
                          {enableColumnResize &&
                            header.column.id !== "select" &&
                            !header.column.columnDef.disableResize && (
                              <div
                                {...{
                                  onDoubleClick: () =>
                                    header.column.resetSize(),
                                  onMouseDown: header.getResizeHandler(),
                                  onTouchStart: header.getResizeHandler(),
                                  className: `resizer ${
                                    tableInstance.options.columnResizeDirection
                                  } ${
                                    header.column.getIsResizing()
                                      ? "isResizing"
                                      : ""
                                  }`,
                                  style: {
                                    transform: "",
                                  },
                                }}
                              />
                            )}
                        </th>
                      )
                    )}
                  </tr>
                ))}
            </thead>
            <tbody ref={tableBodyRef}>
              {data &&
                tableInstance
                  .getRowModel()
                  .rows.map((row: Row<TableData>, i: number) => {
                    const rowElement = getRowElement(
                      String((data[i] as { id: string | number })?.id ?? i)
                    );
                    return (
                      <TableRowElement
                        key={(row?.original as any)?.id ?? i}
                        data={records}
                        index={i}
                        row={row}
                        moveRow={moveRow}
                        rowHover={rowHover}
                        rowClick={enableRowReorder ? () => {} : rowClick}
                        rowElement={rowElement}
                        enableRowReorder={enableRowReorder}
                        enableColumnResize={enableColumnResize}
                        handleDrop={handleDrop}
                      />
                    );
                  })}
            </tbody>
          </table>
          {error && <NoData error>{t("Error loading the table")}</NoData>}
          {displayFallback()}
        </TableWrapper>
      </>
    );
  }
);

const TableRowElement = <TableData extends object>({
  data,
  row,
  index,
  moveRow,
  rowClick,
  link,
  rowHover,
  rowElement,
  enableRowReorder,
  enableColumnResize,
  handleDrop,
}: {
  data: TableData[];
  row: Row<TableData>;
  index: number;
  moveRow: (dragIndex: number, dropIndex: number, item: TableData) => void;
  handleDrop?: () => void;
  rowClick:
    | ((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void)
    | undefined;
  link?: string;
  rowHover?: (row: any) => string | JSX.Element | null;
  rowElement: HTMLTableRowElement;
  enableRowReorder: boolean;
  enableColumnResize: boolean;
}) => {
  const [dragRef] = useState(React.useRef<HTMLTableCellElement>(null));
  const dropRef = React.useRef<HTMLTableRowElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: "row",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = (item as DragItem).index;
      const hoverIndex = index;

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset?.y
        ? clientOffset?.y - hoverBoundingRect.top
        : 0;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      let timeout = 0;
      timeout = setTimeout(() => {
        moveRow(dragIndex, hoverIndex, (item as DragItem).data);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        (item as DragItem).index = hoverIndex;
        clearTimeout(timeout);
      });
    },
    drop() {
      if (handleDrop) {
        handleDrop();
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "row",
    item: { type: "row", index, data: data[index], targetId: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getCellElement = ({
    rowElement,
    cellIdx,
  }: {
    rowElement: HTMLTableRowElement;
    cellIdx: number;
  }) => {
    if (rowElement) {
      const cell = Array.from(rowElement?.children).find(
        (cell: Element) =>
          (cell as HTMLTableCellElement)?.dataset?.testid === String(cellIdx)
      ) as HTMLElement;
      return cell;
    }
    return null;
  };

  useEffect(() => {
    if (enableRowReorder) {
      preview(drop(dropRef));
      drag(dragRef);
    }
  }, [enableRowReorder, preview, drag, drop, dropRef, dragRef]);

  if (data) {
    return (
      <tr
        //key={data[index].id}
        style={{
          opacity: isOver ? 0.3 : 1,
          cursor: rowClick && !enableRowReorder ? "pointer" : "default",
        }}
        id={String((data[index] as { id: string }).id)}
        data-testid={(data[index] as { id: string }).id}
        onClick={link ? undefined : rowClick}
        className={(row.original as any).unread ? "unread" : ""}
        ref={dropRef}
      >
        {enableRowReorder && (
          <td
            ref={dragRef}
            style={{
              cursor: isDragging ? "grabbing" : "grab",
              width: "40px",
            }}
          >
            <ReorderIcon />
          </td>
        )}
        {row.getVisibleCells().map((cell: any, cellIndex: number) => {
          const cellElement = getCellElement({
            rowElement,
            cellIdx: cellIndex,
          });
          const isTextOverflow = () =>
            cellElement ? checkTextOverflow(cellElement).widthOverflow : false;
          if (cell.isRowSpanned) return null;
          else {
            return (
              <td
                key={cellIndex}
                data-testid={cellIndex}
                data-tip={
                  rowHover
                    ? rowHover({
                        ...cell,
                        isTextOverflow: isTextOverflow(),
                      })
                    : null
                }
                data-for={`row-tooltip-${cell.id}`}
                style={{
                  overflow: cell.column?.overflow ?? "hidden",
                  textAlign: cell.column.align === "right" ? "right" : "left",
                  maxWidth: !enableColumnResize
                    ? "200px"
                    : cell.column.getSize() < 200
                    ? "200px"
                    : cell.column.getSize(),
                }}
                rowSpan={cell.rowSpan}
              >
                {(data[index] as { rowURL: string }).rowURL ? (
                  <a
                    href={`${String(
                      (data[index] as { rowURL: string }).rowURL
                    )}`}
                    style={{
                      textOverflow: isTextOverflow() ? "ellipsis" : "none",
                      overflow: isTextOverflow() ? "hidden" : "none",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </a>
                ) : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
                {isTextOverflow() && (
                  <ReactTooltip
                    id={`row-tooltip-${cell.id}`}
                    place="top"
                    data-html={true}
                    effect="solid"
                    backgroundColor="#60676f"
                    multiline={true}
                  >
                    {rowHover
                      ? rowHover({
                          ...cell,
                          isTextOverflow,
                        })
                      : null}
                  </ReactTooltip>
                )}
              </td>
            );
          }
        })}
      </tr>
    );
  } else {
    return null;
  }
};
